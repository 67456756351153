<div class="jumbotron">
    <div class="container">
      <h2>2 Facility Overview</h2>
  
      <p><strong>Section 2</strong> is required information about the project site, facility types the agency intends to
        include in the potential project, challenging facility conditions, and a summary of site utility usage.</p>
    </div>
  </div>
  <div class="container form-horizontal">
  
  
    <div class="row">
      <div class="col-md-6">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">Fill in the fields to populate the NOO</h3>
          </div>
          <div class="panel-body">
            <p><strong>2.1 Agency Mission Statement</strong></p>
  
            <div class="form-group">
  
              <label for="mission_statement" class="col-sm-4 control-label">Mission Text:</label>
  
              <div class="col-sm-8">
                <textarea class="form-control" rows="4" id="mission_statement" name="mission_statement"
                          [(ngModel)]="nooData.mission_statement">Mission Text</textarea>
  
              </div>
            </div>
            <hr>
  
              <div class="row">
              <div class="col-sm-5"><p><strong>2.2 Facility Information</strong></p></div>
  
            <div class="col-sm-7"><a style="cursor: pointer;" (click)="openMod('sampleFacilityDescription')">See Example facility
              description &raquo;</a></div></div>
  
            <br><br>
  
            <div class="form-group">
              <label for="facilityDescription" class="col-sm-4 control-label">Facility Description:</label>
  
              <div class="col-sm-8">
                <textarea class="form-control" rows="4" id="facilityDescription" name="facilityDescription"
                          [(ngModel)]="nooData.facility_building_description">Facility Description</textarea>
              </div>
            </div>
            <div class="form-group">
              <label for="total_sq_ft" class="col-sm-4 control-label">Square Footage:</label>
  
              <div class="col-sm-8">
                <input class="form-control" type="text" id="total_sq_ft" name="total_sq_ft" [(ngModel)]="nooData.total_sq_ft">
              </div>
            </div>
            <br><br>
  
            <p><strong>2.2.1 Federal Building/Facility Types</strong></p>
            <p style="font-weight: normal; font-size: small">(To select more than one option in the list: for Windows or Linux CTRL+click each item, for Mac Cmd+Click)</p>
            
            <div class="form-group">
              <label for="fedBldgTypes" class="col-sm-4 control-label">Select Building Types:</label>
          <div class="col-sm-8">
              <select size="10" id="fedBldgTypes" multiple ng-multiple="true"
              [(ngModel)]="nooData.facilityTypes">
            <option *ngFor= "let option of options " [ngValue]="option" > {{option.label}} </option>
             </select>
            </div>
         </div>
  
            <p><strong>2.2.2 Site Conditions to be considered</strong></p>
  
            <div class="form-group">
              <div class="col-sm-offset-2 col-sm-10" >
  
                <div *ngFor= "let siteCondition of siteconditions" >
                  <input type="checkbox"  [(ngModel)] = "siteCondition.selected" (change)="onCheckboxChange($event, siteCondition)"  >  {{siteCondition.label}}<br>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <label for="noteworthy_site_conditions" class="col-sm-8 control-label">Write in other noteworthy site
                  conditions</label>
              </div>
              <div class="row">
                <div class="col-sm-offset-2 col-sm-10">
                 <textarea class="form-control" id="noteworthy_site_conditions" name="noteworthy_site_conditions" rows="4"
                  [(ngModel)]="nooData.noteworthy_site_conditions"></textarea>
                </div>
  
              </div>
  
            </div>
          </div>
        </div>
  
      </div>
      <div class="col-md-6">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">Default text</h3>
          </div>
          <div class="panel-body">
            <strong>2.1</strong> The mission of
            <mark> {{nooData.agency_name|| "Agency Name"}}</mark>
            &nbsp;is <br>
            <mark> {{nooData.mission_statement|| "Mission Text"}}</mark>
            <br>
            <hr>
            <strong>2.2</strong> Facility Description <br>
            <mark> {{nooData.facility_building_description || "Facility Description"}}</mark><br><br>
            <mark> {{nooData.agency_name|| "Agency Name"}}</mark>
            &nbsp;anticipates that the
            project will include the following building/facility types : <br><br>

            <strong>2.2.1</strong>&nbsp;Facility Types<br>
            <mark *ngFor="let facilityType of nooData.facilityTypes"> {{facilityType.label|| "Specify facility types"}}<br></mark>
            <br><br>

            <strong>2.2.2</strong>&nbsp;Site Conditions to be considered<br>
            <mark *ngFor="let siteCondition of nooData.siteConditions"> {{siteCondition.label || "Make site conditions selections" }} <br></mark>
  
            <br><br>
            <mark>{{nooData.noteworthy_site_conditions || "Other noteworthy site conditions"}}</mark>
            <hr>
  
          </div>
        </div>
      </div>
  
    </div>
  
    <div class="panel panel-default">
      <div class="panel-heading">
        <h3 class="panel-title">
          Default text</h3>
      </div>
      <div class="panel-body">
        <p><strong>2.3&nbsp;Summary information on facility energy and water usage</strong></p>
        <p>The following data is to provide respondents with an approximate profile of facilities to be considered for an
        ESPC and may not be precise. Note that many users will provide this information using their own spreadsheets, etc. instead of the table below.</p>
        <p>The total square footage of the facilities in the potential project scope is
        <mark> {{nooData.total_sq_ft || "sqft" }}</mark>
        &nbsp; sq ft. The average annual energy and water use for these facilities is shown in the table below. For source
        selection purposes, the potential project scope is the full facility. The locations and data provided in this NOO
        may not represent the final project that may be identified as a result of the Investment-Grade Audit prepared by
        the selected ESCO.</p><br>
  
        <div class="text-center"><strong>Average annual energy and water rates and usage for facilities in potential
          project scope.</strong></div>
        <table class="table  table-striped table-eere">
          <thead>
          <tr>
            <th>&nbsp;</th>
            <th colspan="2" style="text-align: center" >Utility Rates</th>
            <th colspan="2" style="text-align: center">Total average annual usage</th>
            <th>&nbsp;</th>
          </tr>
          <tr>
            <th>&nbsp;</th>
            <th>$/Unit</th>
            <th>Unit</th>
            <th>Quantity</th>
            <th>Unit</th>
            <th>Total annual cost ($)</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Electricity</td>
            <td><input class="form-control" type="text" name="electricityRate" [(ngModel)]="nooData.e_1"/></td>
            <td><input class="form-control" type="text" name="electricityUnit" [(ngModel)]="nooData.e_2" placeholder="KWh"/></td>
            <td><input class="form-control" type="text" name="electricityUsage" [(ngModel)]="nooData.e_3"/></td>
            <td><input class="form-control" type="text" name="electricityAnnualUnit" [(ngModel)]="nooData.e_4" placeholder="KWh"/></td>
            <td><input class="form-control" type="text" name="electricityAnnualCost" [(ngModel)]="nooData.e_5"/></td>
          </tr>
          <tr>
            <td>Electricity demand</td>
            <td><input class="form-control" type="text" name="electricityDemandRate" [(ngModel)]="nooData.ed_1"/></td>
            <td><input class="form-control" type="text" name="electricityDemandUnit" [(ngModel)]="nooData.ed_2" placeholder="KWh" /></td>
            <td><input class="form-control" type="text" name="electricityDemandUsage" [(ngModel)]="nooData.ed_3"/></td>
            <td><input class="form-control" type="text" name="electricityDemandAnnualUnit" [(ngModel)]="nooData.ed_4" placeholder="KWh"/></td>
            <td><input class="form-control" type="text" name="electricityDemandAnnualCost" [(ngModel)]="nooData.ed_5"/></td>
          </tr>
          <tr>
            <td>Natural gas</td>
            <td><input class="form-control" type="text" name="naturalGasDemandRate" [(ngModel)]="nooData.ng_1"/></td>
            <td><input class="form-control" type="text" name="naturalGasDemandUnit" [(ngModel)]="nooData.ng_2" placeholder="MMBtu"/></td>
            <td><input class="form-control" type="text" name="naturalGasDemandUsage" [(ngModel)]="nooData.ng_3"/></td>
            <td><input class="form-control" type="text" name="naturalGasDemandAnnualUnit" [(ngModel)]="nooData.ng_4" placeholder="MMBtu"/></td>
            <td><input class="form-control" type="text" name="naturalGasDemandAnnualCost" [(ngModel)]="nooData.ng_5"/></td>
          </tr>
          <tr>
            <td>Potable water</td>
            <td><input class="form-control" type="text" name="potableWaterDemandRate" [(ngModel)]="nooData.pw_1"/></td>
            <td><input class="form-control" type="text" name="potableWaterDemandUnit" [(ngModel)]="nooData.pw_2" placeholder="Kgallons"/></td>
            <td><input class="form-control" type="text" name="potableWaterDemandUsage" [(ngModel)]="nooData.pw_3"/></td>
            <td><input class="form-control" type="text" name="potableWaterDemandAnnualUnit" [(ngModel)]="nooData.pw_4" placeholder="Kgallons"/></td>
            <td><input class="form-control" type="text" name="potableWaterDemandAnnualCost" [(ngModel)]="nooData.pw_5"/></td>
          </tr>
          <tr>
            <td>Non-potable water</td>
            <td><input class="form-control" type="text" name="nonpotableWaterDemandRate" [(ngModel)]="nooData.np_1"/></td>
            <td><input class="form-control" type="text" name="nonpotableWaterDemandUnit" [(ngModel)]="nooData.np_2" placeholder="Kgallons"/></td>
            <td><input class="form-control" type="text" name="nonpotableWaterDemandUsage" [(ngModel)]="nooData.np_3"/></td>
            <td><input class="form-control" type="text" name="nonpotableWaterDemandAnnualUnit" [(ngModel)]="nooData.np_4" placeholder="Kgallons"/></td>
            <td><input class="form-control" type="text" name="nonpotableWaterDemandAnnualCost" [(ngModel)]="nooData.np_5"/></td>
          </tr>
          <tr>
            <td><input class="form-control" name="otherName" placeholder="Other 1" [(ngModel)]="nooData.o"/></td>
            <td><input class="form-control" type="text" name="otherRate1" [(ngModel)]="nooData.o_1"/></td>
            <td><input class="form-control" type="text" name="otherUnit1" [(ngModel)]="nooData.o_2" placeholder="Other Unit"/></td>
            <td><input class="form-control" type="text" name="otherDemandUsage1" [(ngModel)]="nooData.o_3"/></td>
            <td><input class="form-control" type="text" name="otherAnnualUnit1" [(ngModel)]="nooData.o_4" placeholder="Other Unit"/></td>
            <td><input class="form-control" type="text" name="otherDemandAnnualCost1" [(ngModel)]="nooData.o_5"/></td>
          </tr>
          <tr>
            <td><input class="form-control" name="otherName" [(ngModel)]="nooData.o2" placeholder="Other 2"/></td>
            <td><input class="form-control" type="text" name="otherRate2" [(ngModel)]="nooData.o2_1"/></td>
            <td><input class="form-control" type="text" name="otherUnit2" [(ngModel)]="nooData.o2_2" placeholder="Other Unit"/></td>
            <td><input class="form-control" type="text" name="otherDemandUsage2" [(ngModel)]="nooData.o2_3"/></td>
            <td><input class="form-control" type="text" name="otherAnnualUnit2" [(ngModel)]="nooData.o2_4" placeholder="Other Unit"/></td>
            <td><input class="form-control" type="text" name="otherDemandAnnualCost2" [(ngModel)]="nooData.o2_5"/></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    
  </div> 


  <!--Modal Text-->
  <jw-modal  id="sampleFacilityDescription" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="closeMod('sampleFacilityDescription')"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
          <h4 class="modal-title" id="idSampleFacility">Example Facility Description</h4>
        </div>
        <div class="modal-body">
          <p>
            The campus comprises 12 buildings plus a salt dome, maintenance mechanic area with a storage area, sediment pond, and 2 fuel farms on about 287 acres of which 70 acres is paved parking space. The buildings functionality is primarily open office space but also includes data center space, warehouse space, studio space, training space, lobby concourse, auditorium, meeting rooms, cafeteria, guard shacks, structured parking garage, workout facility, etc. Buildings systems vary by buildings, each requiring an individual assessment. Occupants of the Headquarters campus comprise about 7,000 employees.
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="closeMod('sampleFacilityDescription')">Close</button>
        </div>
      </div>
    </div>
</jw-modal>
  
  
import { EvaluationCriteriaComponent } from '../evaluation-criteria/evaluation-criteria.component';
import { HomeComponent } from '../home/home.component';
import  {NoticeAndInvitationComponent} from '../notice-and-invitation/notice-and-invitation.component';
import {FacilityOverviewComponent} from '../facility-overview/facility-overview.component';
import { SubmittalOfExpressionsOfInterestComponent } from '../submittal-of-expressions-of-interest/submittal-of-expressions-of-interest.component';
import { EvaluationFactorsComponent } from '../evaluation-factors/evaluation-factors.component';
import { RequiredSubmittalsComponent } from '../required-submittals/required-submittals.component';
import { EscoSelectionComponent } from '../esco-selection/esco-selection.component';
import { ObjectivesComponent } from '../objectives/objectives.component';

import { Component } from '@angular/core';
import { Routes } from '@angular/router';



export const routes: Routes = [
    {path: 'home',  component: HomeComponent },
    {path: 'notice-and-invitation', component:NoticeAndInvitationComponent},
    {path: 'facility-overview', component: FacilityOverviewComponent},
    {path: 'objectives', component: ObjectivesComponent},
    {path: 'esco-selection', component: EscoSelectionComponent},
    {path: 'required-submittals', component:RequiredSubmittalsComponent},
    {path: 'evaluation-factors', component:EvaluationFactorsComponent},
    {path: 'submittal-of-expressions-of-interest', component: SubmittalOfExpressionsOfInterestComponent},
    {path: 'evaluation-criteria', component: EvaluationCriteriaComponent},
    { path: '', redirectTo: '/home', pathMatch: 'full' }
];
<div class="jumbotron">
    <div class="container">
      <h2>5 Required Submittals</h2>
  
      <p><strong>Section 5</strong> describes the content required in the ESCOs’ expressions of interest. Section 5.1
        covers ESCO experience and qualifications “as related to [the procuring agency’s] facility characteristics and
        project objectives” — which refers to the objectives in Section 3 of the NOO. </p><p>Section 5.2 covers ESCO management
        approach. </p><p>Section 5.3 requires the ESCO to submit contact information for references on past performance.
      </p>
    </div>
  </div>
  <div class="container form-horizontal">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h3 class="panel-title">Complete the text for the required submittals</h3>
      </div>
      <div class="panel-body">
        <p><strong>5 Required Submittals</strong></p>
  
  
  
          <p>ESCOs shall submit the information listed below in their responses to this NOO. Some information is required by the DOE ESPC IDIQs at H.3.1.A and other submittals are specific to this NOO.</p>
  
          <p><strong>5.1 ESCO Technical Competencies and Experience (&nbsp;<input id="page_number_max" type="text" value="5 pages" id="page_number_max"
                          ng-model="nooData.page_number_max" placeholder="5 pages"> max, at least <input
              type="text"
              value="12 pt"
              id="font_size_min"
              ng-model="nooData.font_size_min" placeholder="12 pt">&nbsp;font
            &nbsp;)</strong></p>
  
          <strong>Experience –</strong> Submit information on your experience on up to 5 ESPC projects, or efforts similar to the potential project, that include any of the potential ECMs. Information shall include:
          <ul>
            <li>Specific detailed descriptions of those projects,</li>
            <li>The resulting energy and water savings from those projects,</li>
            <li>Financial Incentives applied to the projects,</li>
            <li>An explanation of how those projects relate to the objectives (or general scope and content) of the
              envisioned project.
            </li>
          </ul>
  
          <strong>Technical Qualifications to Perform an ESPC –</strong> Describe your technical qualifications and abilities <strong>as related to
          our facility characteristics and project objectives</strong> to deliver a successful ESPC project. The description should cover your ability to:
          <ul>
            <li>Design and construct energy and water conservation measures,</li>
            <li>Identify and support agency application for all available financial incentives,</li>
            <li>Sustain performance and savings throughout the entire contract term,</li>
            <li>Plan and perform comprehensive measurement and verification,</li>
            <li>Deliver a project at a fair and reasonable price.</li>
          </ul>
          <strong>Qualifications to Perform an IGA –</strong> Describe your qualifications to conduct an investment-grade audit as it
          relates to the objectives described in this NOO. The description should cover your ability to provide meaningful information to assess viable ECMs, establish baselines, and estimate energy and water savings.
          <hr>
          <p><strong>5.2 ESCO Management Approach (<mark>{{nooData.page_number_max|| "N Pages"}}</mark>
            max, at least <mark>{{nooData.font_size_min|| "N pt"}}</mark> font)</strong>
          </p>
  
          <strong>Management Approach –</strong> Describe your approach for conducting an ESPC at <mark>{{nooData.agency_name_site || "Agency Site"}}</mark>
        that shows an understanding of our objectives and priorities for the project. Address how our opportunities and challenges (as described in this notice) could be managed. Describe the ESCO project team and resources that would be brought to the potential project, including entities you may team with.  Submittals shall include your approach to:
          <ul>
            <li>Managing the design, construction, and continued performance of energy and water conservation measures
              in an efficient and cost effective manner,
            </li>
            <li>Managing cost, schedule and technical performance (including subcontractors if used) including the
              methods to monitor and report progress to customers,
            </li>
            <li>Building quality into the project including process to identify problems and formulate solutions to
              mitigate difficulties,
            </li>
            <li>Performance of operations, maintenance, repair and replacement of equipment to ensure continued
              equipment performance,
            </li>
            <li>Subcontractor source selection including the significance of technical qualifications and price.
            </li>
          </ul>
  
          <strong>Incorporating Small Businesses –</strong> Describe how small businesses were incorporated into the ESPC or similar energy and water conservation projects described above.  Explain how small business will be incorporated into the potential project.
  
          <strong>Organization & Personnel –</strong> It is important that key ESCO staff be involved and available to the agency from ESCO selection through the project acceptance.  Discuss how this continuity in the ESCO project team will be maintained throughout project development and construction.  Submittals shall include your approach to:
          <ul>
            <li>Management style and capacity for resources as demonstrated through the organization structure, key
              personnel, and subcontractor involvement,
            </li>
            <li>Key personnel/positions integral to successful performance,</li>
            <li>Maintaining continuity of the ESCO team throughout ESCO selection, project development, and
              construction.
            </li>
          </ul>
  <hr>
          <p><strong>5.3 References for ESCO Past Performance</strong></p>
  
          Provide contact information for previous customers who can provide information related to the projects
          described under <em>5.1 ESCO Experience.</em> Provide the following information for each reference: Energy Project Agency and Site
          <ul>
            <li>ESPC Task Order/Contract Award Date</li>
            <li>Project Acceptance Date (or ESPC stage, if not yet accepted)</li>
            <li>Previous customer (reference) point of contact</li>
            <li>Name:</li>
            <li>Title/Position [CO, project manager, etc.]:</li>
            <li>Phone:</li>
            <li>E-mail:</li>
          </ul>
  
          These references may be contacted to provide the information called for by a Past Performance Questionnaire
          (Attachment A), which <mark>{{nooData.agency_name || "Agency Name"}}</mark> may
          transmit to references. References may be asked to complete a questionnaire by e-mail directly to <mark>{{nooData.csCoName || "Contracting Officer"}}</mark>,
          Contract Specialist, at <mark>{{nooData.csCoEmail || "Email"}}</mark>. Past
          Performance Questionnaires sent to the Contracting Officer
          directly from the ESCO will not be accepted for evaluation. <mark>{{nooData.agency_name || "Agency Name"}}</mark>
          reserves the right to obtain and evaluate experience and past performance information from other sources
          known to <mark>{{nooData.agency_name || "Agency Name"}}</mark> that are not
          included in the ESCO’s response to this NOO in assessing past performance.
  
          <p><strong>Other agency-required submittals</strong></p>
  
          <div class="form-group">
            <label for="other_submittals" class="col-sm-2 control-label">Write in other agency required submittals</label>
  
            <div class="col-sm-10">
        <textarea class="form-control" id="other_submittals" name="other_submittals"
                  ng-model="nooData.other_submittals" rows="4"></textarea>
            </div>
          </div>
  
  
  
      </div>
    </div>
  </div>
  
  
  
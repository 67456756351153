import { nooDataDefault } from './../shared/NooData';
import { Component, OnInit, Input } from '@angular/core';
import { NooDataService } from './../services/noo-data.service';

@Component({
  selector: 'app-required-submittals',
  templateUrl: './required-submittals.component.html',
  styleUrls: ['./required-submittals.component.scss']
})
export class RequiredSubmittalsComponent implements OnInit {
  nooData: any;

  constructor(private nooDataService: NooDataService) { }

  ngOnInit(): void {
    this.nooDataService.getValue().subscribe((value) => {
      this.nooData = value;
    });
  }

}

import { options } from './../shared/facilityTypes';
import { conditions } from './../shared/siteConditions';
import { Component, OnInit } from '@angular/core';
import { NooDataService } from './../services/noo-data.service';
import { ModalService } from '../_modal';
import {nooDataDefault} from './../shared/NooData';


@Component({
  selector: 'app-facility-overview',
  templateUrl: './facility-overview.component.html',
  styleUrls: ['./facility-overview.component.scss']
})
export class FacilityOverviewComponent implements OnInit {
  nooData: any = nooDataDefault;
  siteconditions = conditions;
  options = options;


  constructor(private nooDataService: NooDataService, private modalService: ModalService) { }

  ngOnInit(): void {
    this.nooDataService.getValue().subscribe((value) => {
      this.nooData = value;
    });
    console.log(this.nooData.siteConditions);
  }

  openMod(id: string) {
    this.modalService.open(id);
}

closeMod(id: string) {
    this.modalService.close(id);
}


 onCheckboxChange(event, value) {
  
    if (event.target.checked) {
      
      this.nooData.siteConditions.push(value);
      this.nooDataService.setValue(this.nooData); 

      
    } 

    if (!event.target.checked) {
      let index = this.nooData.siteConditions.indexOf(value);
      if (index > -1) {
        this.nooData.siteConditions.splice(index, 1);
        this.nooDataService.setValue(this.nooData); 
      } };
    console.log(this.nooData.siteConditions);
    
  }

  

  
  }


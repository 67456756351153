<div class="jumbotron">
    <div class="container">
      <h2>4 ESCO Selection</h2>

      <p><strong>Section 4</strong> describes the ESCO selection process the agency will use. This is the recommended
        selection method for a compliant, efficient selection that will best address the agency’s goals: selection based
        on qualifications. Contact your FPE for more help if using a different selection method such as selection by preliminary assessment.</p>

      <p><a class="btn btn-primary btn-lg" (click)="open('escoSelection')">Learn more &raquo;</a></p>
    </div>
  </div>
  <div class="container form-horizontal">
    <div class="panel-body">
      <div class="tr">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">Default text</h3>
          </div>
          <div class="panel-body">
          <p><strong>4.1 ESCO Selection Process</strong></p>

          <mark> {{nooData.agency_name || "Agency"}}</mark> will select an ESCO for potential task order award in accordance with the Selection Based on Qualifications Method at section H.3.8.1 of the DOE’s Fourth Generation ESPC IDIQ.<br><br>
          <mark> {{nooData.agency_name|| "Agency"}}</mark> may use a two-step process for ESCO
          selection. In the first down-selection process (the first step), after evaluation of the ESCOs’ responses to
          this NOO, <mark> {{nooData.agency_name|| "Agency"}}</mark> may select two or more
          ESCOs deemed most qualified for further consideration for selection. The process and requirements for this first
          step are described in this notice.<br><br>
          In the second step of ESCO selection, after a first down-selection, <mark> {{nooData.agency_name|| "Agency"}}</mark>
          may request further information from the remaining ESCOs. <mark> {{nooData.agency_name|| "Agency"}}</mark>
          will provide ESCOs with evaluation requirements for the second down-selection at that time. Agencies may conduct
          discussions with selected ESCOs. Discussions may including interviews, written responses, presentations, ESCO
          resume interviews, that address details related to previously submitted information. ESCOs may be required to
          participate in interviews at <mark> {{nooData.agency_name|| "Agency"}}</mark>
          as part of the second down-selection process.
          <div class="checkbox" *ngIf="!nooData.multipleProjects">
            <label>
              <input id="singleEsco" type="checkbox" [(ngModel)]="nooData.singleEsco" (change) = "updateSelection($event, 'single_esco_text', 'singleEsco', 0)"  /> The agency will select one ESCO to conduct a preliminary assessment (PA).
            </label>
          </div>
          <div class="checkbox" *ngIf="!nooData.singleEsco">
              <label>
                  <input id="multipleProjects" type="checkbox" [(ngModel)]="nooData.multipleProjects" (change)="updateSelection($event, 'multiple_projects_text', 'multipleProjects', 0)" /> This notice includes
                  several anticipated agency projects, and multiple awards are anticipated. Multiple ESCOs may be selected to
                  conduct PAs for multiple projects.
                </label>      
          </div>
          <br>

          <p>The Government reserves the right to adjust the selection process if agency deems it to be in the best
            interest of the Government.</p>
            <p><strong>4.2 Optional Project Timeline&nbsp;&nbsp;<button type="button" class="btn btn-xs btn-primary" data-toggle="collapse" data-target="#optimeline">&nbsp;&raquo;&nbsp;</button></strong></p>
            <span id="optimeline" class="collapse">
            <p>The time spans in the optional project timeline are examples only and can be edited to user's specifications in the MS Word NOO draft.</p>
              <div class="checkbox">
                <label>
                  <input id="includeOptionalTimeline" type="checkbox" [(ngModel)]="nooData.includeOptionalTimeline" ng-init="checked=true"/> Include optional timeline in NOO.
                </label>
              </div>
            <p>
              The anticipated timeline for this process is as follows:
              <tr>
                <div class="col-sm-8">•	Closing date for receipt of Expressions of Interest:</div>
                <div class="col-sm-4 text-right">Date, 5:00 p.m. EST</div>
              </tr>
              <tr>
                <div class="col-sm-8">•	Completion of agency evaluation process:</div>
                <div class="col-sm-4 text-right">Date + 14 days</div>
              </tr>
              <tr>
                <div class="col-sm-8">•	Agency notification to ESCOs of down-selection:</div>
                <div class="col-sm-4 text-right">+ 2 days</div>
              </tr>
              <tr>
                <div class="col-sm-8">•	Unsuccessful ESCO debrief opportunity:</div>
                <div class="col-sm-4 text-right">Date to be determined</div>
              </tr>
              <tr>
                <div class="col-sm-8">•	Additional information closing date for receiving additional information
                  from ESCOs proceeding to interviews:
                </div>
                <div class="col-sm-4 text-right">+ 15 business days</div>
              </tr>
              <tr>
                <div class="col-sm-8">•	Interviews:
                </div>
                <div class="col-sm-4 text-right">closing date to 5 business days later</div>
              </tr>
              <tr>
                <div class="col-sm-8">•	Agency notification to ESCOs of down-selection to one ESCO:
                </div>
                <div class="col-sm-4 text-right">one to three days after last interview</div>
              </tr>
              <tr>
                <div class="col-sm-8">•	Unsuccessful ESCO debrief opportunity:
                </div>
                <div class="col-sm-4 text-right">Date to be determined</div>
              </tr>
              <tr>
                <div class="col-sm-8">•	<u>PA Kickoff Meeting</u> with successful ESCO:
                </div>
                <div class="col-sm-4 text-right">1 - 7 days after notification</div>
              </tr>
              <tr>
                <div class="col-sm-8">•	<u>ESCO submission of PA</u>; agency evaluation; issuance of NOITA:
                </div>
                <div class="col-sm-4 text-right text-right">1 - 7 days after notification</div>
              </tr>
              <tr>
                <div class="col-sm-8">•	<u>Project Development to Award</u>:
                </div>
                <div class="col-sm-4 text-right">&nbsp;</div>
              </tr>
              <tr>
                <div class="col-sm-8">•	TO RFP and IGA:
                </div>
                <div class="col-sm-4 text-right">+ 90 days</div>
              </tr>
              <tr>
                <div class="col-sm-8">•	Proposal development; agency review & evaluation:
                </div>
                <div class="col-sm-4 text-right">+ 30-120 days</div>
              </tr>
              <tr>
                <div class="col-sm-8">•	Final negotiations and TO award:
                </div>
                <div class="col-sm-4 text-right">+ 30-60 days</div>
              </tr>
            </p>
            </span>
        </div>
      </div>
    </div>
  </div>

  </div>



<!--Modal Text -->
<jw-modal id="escoSelection" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog wide">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="close('escoSelection');"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
          <h4 class="modal-title" id="idUserGuide">ESCO Selection - User Guide</h4>
        </div>
        <div class="modal-body">
          <p>
            <em>Agency contracting officers (COs) may exercise discretion in ESCO selection procedures, as stated in section H.3.C of the DOE IDIQ contracts:</em><br><br>
            <em>H.3.C   Contractor Selection Procedures  –  The agency CO must provide each contractor a fair opportunity to be considered for any TO award.  In selecting a contractor, the agency CO may exercise discretion in developing contractor selection procedures, as long as the agency CO complies with applicable laws and regulations, local agency policies, and the framework of the selection methods established below.  The agency CO shall indicate, in the initial notice to all IDIQ holders, the selection method and down selections the agency intends to use for the entire ordering process.  The agency CO reserves the right to adjust the selection process. …</em>
          </p>
          <p><strong>ESCO Selection by Qualifications Method (SBQ)</strong></p>
          <p>The ESCO selection method in Section 4 is <em>selection based on qualifications</em> (SBQ).   For more information or help with planning an ESPC  procurement using other methods, see DOE ESPC IDIQ section H.3 to review requirements, and contact your FPE.</p>
          <p>FEMP experience demonstrates that SBQ is a resource-efficient method that can elicit as much useful information for matching ESCO capabilities with agency requirements as more complex and resource-intensive methods, and also expedites contractor selection and reduces delays.</p>
          <p>The NOO template includes a selection process that incorporates the DOE IDIQ requirements while promoting opportunities to conduct interviews, receive presentations, or request additional information from the most highly qualified contractors before down-selecting to one contractor to prepare a Preliminary Assessment.</p>
          <p>The selection process in the NOO is an adaptation of the DOE IDIQ two-step selection process, which authorizes additional down-selections as necessary for agencies to address their desired competition needs.  The first step of the DOE IDIQ two-step process is requesting and evaluating qualifications for conducting IGAs and any other meaningful information to select two or more contractors for further consideration.  The second step is requesting and evaluating ESCOs’ experience and any other meaningful information to select one or more contractors to conduct a Preliminary Assessment.  These two steps have been combined into one (step one of the process in Section 4) because most agencies have found it difficult to make decisions based only on a contractor’s ability to conduct an IGA.  Therefore this template considers all contractors qualified to perform IGAs and requests the additional past experience and other meaningful information as one step.  After reviewing all information, agencies are encouraged to down-select to the most highly qualified for further consideration or selection.  Step two of the process in Section 4 may include interviews, presentations, or projects resumes, or other methods to evaluate and make a final down-selection to one contractor to conduct a Preliminary Assessment.</p>
          <!--<p>Note that this template includes required and suggested submittals for a Step 2 down-selection in the SBQ process, though that information (as noted in NOO Section 4) will be requested from ESCOs remaining in consideration after the first down-selection.  (See Options document Appendix A.)</p>-->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="close('escoSelection')">Close</button>
        </div>
      </div>
    </div>
  </jw-modal>

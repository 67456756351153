
<div class="jumbotron">
    <div class="container">
      <h2>1 Notice and Invitation</h2>
      <p><strong>Section 1</strong> Enter pertinent agency and site information to notify ESCOs of the potential ESPC and invite ESCOs to submit Expressions of Interest.  </p>
    </div>
  </div>
  
  <div class="container form-horizontal">
    <div class="row">
      <div class="col-md-6">
        <div class="panel panel-default">
  
          <div class="panel-heading">
            <h3 class="panel-title">These fields will populate the NOO in various locations throughout the template</h3>
          </div>
          
          <div class="panel-body">
            <div (change)="formChanged()">
              <div class="form-group">
                <label for="issue_date" class="col-sm-3 control-label">Issue Date:</label>
                <div class="col-sm-9">
                  <input class="form-control" type="text" id="issue_date" name="issue_date" [(ngModel)]="nooData.issue_date" required aria-required/>
                  
                </div>
              </div>
              <div class="form-group">
                <label for="agency_name" class="col-sm-3 control-label">Agency Name:</label>
                <div class="col-sm-9">
                  <input id="agency_name" class="form-control" type="text" name="agency_name" [(ngModel)]="nooData.agency_name"/>
                </div>
              </div>
              <div class="form-group">
                <label for="agency_name_location" class="col-sm-3 control-label">Agency Location:</label>
                <div class="col-sm-9">
                  <input class="form-control" type="text" id="agency_name_location" name="agency_name_location" [(ngModel)]="nooData.agency_name_location"/>
                </div>
              </div>
              <div class="form-group">
                <label for="agency_name_site" class="col-sm-3 control-label">Agency Site:</label>
                <div class="col-sm-9">
                  <input class="form-control" type="text" id="agency_name_site" name="agency_name_site" [(ngModel)]="nooData.agency_name_site"/>
                </div>
              </div>
              <div class="form-group">
                <label for="contracting_officer" class="col-sm-3 control-label">Contracting Officer:</label>
                <div class="col-sm-9">
                  <input class="form-control" type="text" id="contracting_officer" name="contracting_officer" [(ngModel)]="nooData.contracting_officer" required aria-required/>
                  
                </div>
              </div>
              <div class="form-group">
                <label for="contact_info" class="col-sm-3 control-label">Contact Information:<br><p style="font-weight: normal; font-size: small">(Shift + Enter for new line)</p></label>
                <div class="col-sm-9">
                  <textarea class="form-control" rows="3" id="contact_info" name="contact_info" [(ngModel)]="nooData.contact_info"></textarea>
                </div>
              </div>
              <div class="form-group">
                <label for="agency_ombudsman" class="col-sm-3 control-label">Agency Ombudsman:</label>
                <div class="col-sm-9">
                  <input class="form-control" type="text" id="agency_ombudsman" name="agency_ombudsman" [(ngModel)]="nooData.agency_ombudsman" required aria-required/>
                  
                </div>
              </div>
              <div class="form-group">
                <label for="contact_info" class="col-sm-3 control-label">Contact Information:<br><p style="font-weight: normal; font-size: small">(Shift + Enter for new line)</p></label>
                <div class="col-sm-9">
                  <textarea class="form-control" rows="3" id="agency_ombudsman_contact_info" name="contact_info" [(ngModel)]="nooData.agency_ombudsman_contact_info"></textarea>
                </div>
              </div>
              <div class="form-group">
                <label for="facility_name" class="col-sm-3 control-label">Facility Name:</label>
                <div class="col-sm-9">
                  <input class="form-control" type="text" id="facility_name" name="facility_name" [(ngModel)]="nooData.facility_name"/>
                </div>
              </div>
              <div class="form-group">
                <label for="facilityAddress" class="col-sm-3 control-label">Facility Address:<br><p style="font-weight: normal; font-size: small">(Shift + Enter for new line)</p></label>
                <div class="col-sm-9">
                  <textarea class="form-control" rows="3" id="facilityAddress" name="facilityAddress" [(ngModel)]="nooData.facility_address"></textarea>
                </div>
              </div>
              <div class="form-group">
                <label for="has_multiple_tos" class="col-sm-3 control-label">Include Multiple TO Text?</label>
                <div class="col-sm-9"><input id="has_multiple_tos" type="checkbox" (change)="updateSelection($event, 'multiple_to_text', 'has_multiple_tos')" [(ngModel)]="nooData.has_multiple_tos" />
  
  
                  Multiple task orders. <mark> {{nooData.agency_name|| "Agency Name"}} </mark> &nbsp;
                  reserves the right to determine whether this requirement will result in one task order or multiple task orders.
                </div>
              </div>
              
            </div> 
          </div> 
        </div> 
      </div> 
      
      <div class="col-md-6">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">Default text</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="col-md-2">
                  TO:
                </div>
                <div class="col-md-6">
                  All Fourth Generation DOE ESPC IDIQ Contract Holders  
                </div>
                <div class="col-md-4">
                  DATE:  <mark>{{nooData.issue_date || "Date of Issue"}}</mark>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="col-md-2 ">FROM:</div>
                  <div class="col-md-10"><mark>{{nooData.agency_name || "Agency Name"}}</mark><mark> {{nooData.agency_name_site|| "Site"}}</mark></div>
              </div>
            </div>
            <br><br>
            <div class="row">
              <div class="col-md-2">
                SUBJECT:
              </div>
              <div class="col-md-10">
                Notice of Opportunity (NOO) for Energy Savings Performance Contract (ESPC) Task Order at <mark> {{nooData.agency_name_site|| "Site"}}</mark> and Request for Contractor Expressions of Interest
              </div>
            </div>
            <hr>
            <p><strong>1 Notice and Invitation</strong></p>
            <p><mark> {{nooData.agency_name|| "Agency Name"}}</mark> is interested in pursuing a potential ESPC project at <mark> {{nooData.agency_name_site || "Site"}}&nbsp;{{nooData.agency_name_location || "Location"}}</mark>.</p>
            <p>This notice of opportunity invites current holders of the Department of Energy’s (DOE’s) Fourth Generation ESPC indefinite-delivery (IDIQ) contract to submit a written response (an expression of interest) to be considered for contractor selection for this potential task order award.</p>
  
            <p>The scope of any task order (TO) awarded for this project is the buildings and facilities as identified in this notice.  While certain energy and water conservation measures (ECMs) will be included at the time of the original TO award, <mark> {{nooData.agency_name|| "Agency Name"}}</mark> may, at any time during the performance period, request the selected ESCO to do further analysis to determine whether expansion of ECMs included in the original TO or additional ECMs for the identified buildings and facilities are feasible.  If deemed viable and desirable,  <mark> {{nooData.agency_name|| "Agency Name"}}</mark> may incorporate the additional ECMs into this project by way of TO modification.</p>
          </div>
        </div>
      </div>
    </div>
  
  
  </div>
  
<footer class="footer-site">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
        <p class="footer-tagline"> <br>
            ESCO Selector is a resource of the Department of Energy's Federal Energy Management Program (FEMP)

        </p>
        <p class="footer-links">
          <a href ="https://www.energy.gov/eere/femp/federal-energy-management-program">Contact Us</a> |
          <a href="https://www.energy.gov/eere/femp/federal-energy-management-program">Federal Energy Management Program (FEMP)</a> |
          <a href="https://www.energy.gov/eere/office-energy-efficiency-renewable-energy">Office of Energy Efficiency &amp; Renewable Energy</a> |
          <a href="//www.ornl.gov/ornl/contact-us/Security--Privacy-Notice">Security &amp; Privacy Notice</a><br>
          To report issues with the site please contact <a href="mailto:awadrl@ornl.gov">site administrator</a>.<br>
        </p>
       
        <div class="center-block text-center">
           
                <a href="//www.energy.gov/eere/office-energy-efficiency-renewable-energy">
                    <img src="../assets/images/eere_logo_horiz_blue.jpg" alt="Office of Energy Efficiency and Renewable Energy Logo" width=150 height=30 ><img/></a>
        </div>
            
        </div>
    </div>
      </div>
  </footer>

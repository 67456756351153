<div class=" jumbotron ">
    <div class="container">
    <h1>ESCO Selector</h1>
    <!--<p>This application is for agencies to use to complete an NOO that complies with federal requirements and meets agency needs. Any part of the default NOO text can be edited. The application will also generate an NOO-response-evaluation form that incorporates the evaluation factors identified in the NOO. </p>-->
    <p>This tool helps agencies create an NOO that complies with federal requirements and meets agency needs.  It is a standard NOO template that is easily tailored via the tool.  The tool produces an NOO in Word format that is further editable as needed.  The tool also generates an editable NOO response evaluation form that incorporates the evaluation factors identified in the NOO. </p>
    <p>Get started:</p>
      <ul>
        <li>Click the "Start Now" button below or "1 Notice and Invitation" in the menu above.</li>
        <li>Proceed by completing the seven sections of the NOO in the menu.</li>
        <li>Finish by selecting the "Download Documents" option desired. The current version of the NOO Word document is viewable at any time.</li>
      </ul>
      <p>Note that the form fields will stay populated within your browser so that you can return to your work at a later date and not lose any progress.</p>
    <p routerLink="/notice-and-invitation"><a class="btn btn-primary btn-lg" role="button" ui-sref="templater.notice_and_invitation">Start Now &raquo;</a></p>
    </div>
  </div>
  
  <div class="text-center row col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1">
    <div class="row">
      <div class="col-md-6">
        <h2>FEMP Support</h2>
        <p>To get help drafting your <abbr title="Notice of Opportunity" class="initialism">NOO</abbr> through this automated template, contact your Federal Project Executive (FPE)
          or Project Facilitator (PF).</p>
        <p><a class="btn btn-default" (click)="openModal('supportModal')" >Contact  &raquo;</a></p>
      </div>
  
      <div class="col-md-6">
        <h2>Compliance</h2>
        <p>This template complies with the <abbr title="Department of Energy" class="initialism">DOE</abbr> ESPC IDIQ and federal laws and regulations. </p>
        <p><a class="btn btn-default" (click)="openModal('compianceModal')">View details &raquo;</a></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h2>Best Practices</h2>
        <p>FEMP finds that agencies who establish and use a consistent approach such as this template are able to issue their <abbr title="Notice of Opportunity" class="initialism">NOO</abbr>s quickly and are happier with their ESCO selection results.</p>
        <p><a class="btn btn-default"  (click)="openModal('bestModal')">View details &raquo;</a></p>
      </div>
      <div class="col-md-6">
        <h2>Documents Produced</h2>
        <p>The editable MS Word documents produced by this template creator are </p>
        <div class="row">
          <div class="col-lg-3">
            &nbsp;
          </div>
          <div class="col-lg-6 text-left">
            <ul><li>Notice of Opportunity (NOO) </li><li>NOO-Response-Evaluation Forms</li></ul>
          </div>
  
          <div class="col-lg-3">
            &nbsp;
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">&nbsp;</div>
          <div class="col-lg-6"><a class="btn btn-default" (click)="openModal('docsModal')">View details &raquo;</a></div>
          <div class="col-lg-3">&nbsp;</div>
        </div>
  
      </div>
    </div>
  </div>
  

  <!--Modal Text-->
  <jw-modal id="supportModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="closeModal('supportModal')"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
          <h4 class="modal-title" id="idFempSupport">FEMP Support</h4>
        </div>
        <div class="modal-body">
          <p>To get help drafting your NOO through this automated template, contact your Federal Project Executive (FPE) or Project Facilitator (PF).</p>
          <p>
            <img src="../assets/images/RegionMap.png" class="img-responsive"/>
          </p>
          <p>Northeast Region:  <a href="mailto:thomas.hattery@ee.doe.gov?subject=ESCO%20Selector">Thomas Hattery</a>, 202-256-5986, thomas.hattery@ee.doe.gov</p>
          <p>Western Region:  <a href="mailto:wolfsc@ornl.gov?subject=ESCO%20Selector">Scott Wolf</a>, 360-866-9163, wolfsc@ornl.gov</p>
          <p>Southeast Region and Midwest:  <a href="mailto:culbrethcd@ornl.gov?subject=ESCO%20Selector">Doug Culbreth</a>, 919-870-0051, culbrethcd@ornl.gov</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="closeModal('supportModal')">Close</button>
        </div>
      </div>
    </div>
  </jw-modal>

  <jw-modal  id="compianceModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="closeModal('compianceModal')"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
          <h4 class="modal-title" id="idCompliance">Compliance</h4>
        </div>
        <div class="modal-body">
          <p>This template complies with the <abbr title="Department of Energy" class="initialism">DOE</abbr> ESPC IDIQ and federal laws and regulations. This template reflects </p>
          <ul>
            <li>Federal regulations and laws governing federal ESPCs</li>
            <li>Federal procurement regulations,</li>
            <li>the requirements in the <abbr title="Department of Energy" class="initialism">DOE</abbr> ESPC IDIQ, and</li>
            <li>FEMP’s best practices for procurement of federal ESPC projects.</li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="closeModal('compianceModal')">Close</button>
        </div>
      </div>
    </div>
  </jw-modal>

  <jw-modal  id="bestModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="closeModal('bestModal')"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
          <h4 class="modal-title" id="idBest">Best Practice</h4>
        </div>
        <div class="modal-body">
          <p>FEMP finds that agencies who establish and use a consistent approach such as this template are able to issue their NOOs quickly and are happier with their ESCO selection results. Best practices include the following.</p>
          <ul>
            <li>Use the FEMP NOO template and customize as applicable.</li>
            <li>Keep the NOO project scope as broad as possible to allow the ESCO to propose comprehensive and innovative solutions</li>
            <li>Identify 2-3 facility needs or wish-list items for the project and state them in the NOO. This should elicit information in ESCOs’ NOO responses to allow evaluating agencies to match ESCO qualifications with agency needs.</li>
            <li>Use the selection based on qualifications (SBQ) method of ESCO selection (discussed in Section 4).</li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="closeModal('bestModal')">Close</button>
        </div>
      </div>
    </div>
  </jw-modal>

  <jw-modal  id="docsModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="closeModal('docsModal')"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
          <h4 class="modal-title" id="idDocs">Documents Produced by <em>ESCO Selector</em></h4>
        </div>
        <div class="modal-body">
          <p><em>ESCO Selector</em> generates populated and basic hard-copy documents in fully editable MS Word format</p>
          <ol>
            <li>A draft notice of opportunity (NOO) populated with agency/facility-specific information, objectives, and significant evaluation factors can be downloaded at any time.</li>
            <li>A standard basic NOO draft with shaded text where agency-specific information is needed can be downloaded.</li>
            <li>NOO-Response-Evaluation Forms for individual evaluator use and for documenting team consensus are generated automatically as the agency builds the NOO, based on information entered in the NOO.</li>
          </ol>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="closeModal('docsModal')">Close</button>
        </div>
      </div>
    </div>
  </jw-modal>


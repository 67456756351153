import { Component, OnInit } from '@angular/core';
import { ModalService } from '../_modal';

@Component({
  selector: 'app-evaluation-criteria',
  templateUrl: './evaluation-criteria.component.html',
  styleUrls: ['./evaluation-criteria.component.scss']
})
export class EvaluationCriteriaComponent implements OnInit {
  

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  openM(id: string) {
    this.modalService.open(id);
  }
  
  closeM(id: string) {
    this.modalService.close(id);
  }
  

}

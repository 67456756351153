
  <header role="banner" id="banner">
    <div class="banner-eere">
      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-xs-6">
            <!-- Horizontal Banner -->
            <img class="banner-logo hidden-xs"
                 src="../assets/images/logo_eere.png" alt=""  usemap="#bannermap-h" >
            <map id="bannermap-h" name="bannermap-h">
              <area shape="rect" coords="0,0,140,45"   href="http://energy.gov/"     alt="U.S. Department of Energy">
              <area shape="rect" coords="141,0,300,45" href="http://energy.gov/eere" alt="Energy Efficiency & Renewable Energy">
            </map>

            <!-- Vertical Banner -->
            <!--<img class="banner-logo visible-xs img-responsive"
                 src="images/logo_eere_vert.png" alt=""  usemap="#bannermap-v">

            <map id="bannermap-v" name="bannermap-v">
              <area shape="rect" coords="0,0,130,45"  href="http://energy.gov/"     alt="U.S. Department of Energy">
              <area shape="rect" coords="0,45,183,96" href="http://energy.gov/eere" alt="Energy Efficiency & Renewable Energy">
            </map>-->
          </div>
          <button
            type="button"
            class="navbar-toggle searchbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#searchbar-collapse">

            <i class="fa fa-custom-search-times"></i><span class="sr-only">Toggle Downloads</span>
          </button>

          <div class="col-sm-6">

            <div class="banner-tools">

              <div class="navbar-collapse collapse searchbar" id="searchbar-collapse" >

                <form
                  role="form"
                  name="templaterForm"
                  ng-submit="processForm(templaterForm.$valid)" novalidate>
                  <div class="row">


                    <button  type="button" class="dropdown-toggle btn btn-success" data-toggle="dropdown" aria-expanded="false" *ngIf="router.url != '/home'">
                      Download Documents <span class="caret"></span></button>

                    <ul class="dropdown-menu" role="menu">

                      <li><a (click)="nooTemplate()" >NOO</a></li>
                      <li><a (click)="populateNooTemplate()" >NOO Populated</a></li>
                      <li class="divider"></li>
                      <li><a (click)="responseEvalIndividual()">Evaluation Template Individual</a></li>
                      <li><a (click)="responseEvalConsensus()">Evaluation Template Consensus</a></li>
                      <li><a (click)="responseEvalSummary()">Evaluation Templates Summary</a></li>
                      <li><a (click)="responseEvalDefinitions()">Evaluation Templates Instructions-Definitions</a></li>
                      
                    </ul>

                    <button *ngIf="router.url == '/home'" type="button" class="btn btn-success" (click)="clearStorage()">Clear All Forms</button>

                  </div>
                </form>
        </div>
        </div>
      </div>
          </div></div>
    </div>

  </header>
  <div class="banner-site">
    <div class="container">
      <div class="row">
        <div class="sitename">
          <a ui-sref="main">ESCO Selector</a>
        </div>
      </div>
    </div>
  </div>
  <div class="topnav">
    <div class="container">
      <div class="navbar" role="navigation">
        <div class="navbar-header">
          <button type="button"
                  class="collapsed navbar-toggle"
                  data-toggle="collapse"
                  data-target="#navbar"
                  aria-expanded="false"
                  aria-controls="navbar">
            <span class="sr-only">Toggle navigation</span>
            <i class="fa fa-arrow-down"></i> Menu
          </button>

        </div>

        <div id="navbar" class="navbar-collapse collapse">
          <ul class="nav navbar-nav">
            <li routerLink="/home" ui-sref-active="active"><a>Home</a></li>
            <li routerLink="/notice-and-invitation" ui-sref-active="active"><a>1 Notice &amp; Invitation</a></li>
            <li routerLink="facility-overview" ui-sref-active="active"><a>2 Facility Overview</a></li>
            <li routerLink="objectives" ui-sref-active="active"><a>3 Objectives</a></li>
            <li routerLink="esco-selection" ui-sref-active="active"><a>4 ESCO Selection</a></li>
            <li routerLink="required-submittals" ui-sref-active="active"><a>5 Required Submittals</a></li>
            <li routerLink="evaluation-factors" ui-sref-active="active"><a>6 Evaluation Factors</a></li>
            <li routerLink="submittal-of-expressions-of-interest" ui-sref-active="active"><a>7 Submittal Instructions</a></li>
            <li routerLink="evaluation-criteria" ui-sref-active="active"><a>8 Evaluation Criteria</a></li>
          </ul>
        </div>

      </div>
    </div>
  </div>




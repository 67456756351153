export const waterEcms = [
    {value: 0, 'label': 'High efficiency toilets and urinals with piston flush valve technology'},
    {value: 1, 'label': 'High efficiency faucets and showerheads'},
    {value: 2, 'label': 'Weather based irrigation control systems'},
    {value: 3, 'label': 'Irrigation system audit and efficiency improvements'},
    {value: 4, 'label': 'High efficiency irrigation equipment'},
    {value: 5, 'label': 'Water efficient commercial kitchen equipment'},
    {value: 6, 'label': 'Water efficient laundry equipment'},
    {value: 7, 'label': 'Cooling tower water management'},
    {value: 8, 'label': 'Steam system water management'},
    {value: 9, 'label': 'Water efficient laboratory and medical equipment'},
    {value: 10, 'label': 'Leak detection technology'},
    {value: 11, 'label': 'Air handling condensate harvesting'},
    {value: 12, 'label': 'Rainwater harvesting'},
    {value: 13, 'label': 'Reclaimed wastewater'},
    {value: 14, 'label': 'Water metering'},
    {value: 15, 'label': 'Other'},
    {value: 16, 'label': 'IDIQ Technology Categories:  '}

  ];

<div class="jumbotron">
    <div class="container">
      <h2>6 Significant Evaluation Factors</h2>
      <p><strong>Section 6</strong> states the significant evaluation factors the agency expects to use in evaluating ESCOs’ responses to the NOO and their relative importance, as required by federal ESPC regulations and the DOE ESPC IDIQ. The NOO is simplified and streamlined by putting these factors in the context of the agency’s requirements.</p>
      <p><a class="btn btn-primary btn-lg" (click)="openMOdal('evaluationFactors')" role="button">Learn more &raquo;</a></p>
    </div>
  
  </div>
  <div class="container form-horizontal">
  
      <div class="panel panel-default">
        <div class="panel-heading">
          <h3 class="panel-title">Default text</h3>
        </div>
        <div class="panel-body">
          <p><strong>6 Significant Evaluation Factors</strong></p>
          <p>The significant evaluation factors that the agency expects to consider in reviewing the responses and their relative importance are as follows: </p>
          <p><strong>6.1 The ESCO's Technical Competencies and Experience</strong> in relation to our facility characteristics and project objectives to (a) conduct an investment grade audit that provides meaningful information to assess viable energy and water conservation measures (ECMs) and establish baselines and project savings; (b) design and construct ECMs similar to those stated in the “objectives” section of this NOO; and (c) provide sustained savings and performance as reported through annual measurement and verification reports. Technical risk will be assessed to determine the degree to which the ESCO’s experience, technical qualifications, and qualifications to perform an IGA demonstrate the potential to satisfy the Government’s objectives for successful performance without the need for increased Government oversight.</p>
  
          <hr>
          <p><strong>6.2  The ESCO’s Management approach for conducting an ESPC </strong>including project management capabilities and personnel qualifications as related to our facility characteristics and project objectives to:  (a) manage the design, construction, and continued performance of ECMs in an efficient and cost effective manner;
            (b) management style and capacity for resources as demonstrated through the organization and key personnel;  and (c) incorporate the use of small businesses to implement and sustain savings.  Technical risk will be assessed to determine the degree to which the ESCO’s management approach may cause disruption of schedules, degradation of performance, or likelihood of unsuccessful contract performance.
          </p>
          <hr>
          <p><strong>6.3 The ESCO’s Past Performance</strong> (feedback from previous customers). To assess the degree of confidence the Agency has in the ESCO’s ability to supply ESPC services that meet the user’s needs based on a demonstrated record of performance.</p>
  
          <hr>
          <p><strong>6.4 Additional Evaluation Factors</strong></p>
  
          <div class="form-group">
            <label for="other_evaluation_factors" class="col-sm-2 control-label">Write in further evaluation factors</label>
  
            <div class="col-sm-10">
        <textarea rows="4" class="form-control" id="other_evaluation_factors" name="other_evaluation_factors"
        [(ngModel)]="nooData.other_evaluation_factors"></textarea>
            </div>
          </div>
          <hr>
          <p><strong>6.5 Relative importance of evaluation factors</strong></p>
          <p>Stating the relative importance of evaluation factors in the NOO is required. Check the box to use the default text OR write in your own language.</p>
  
          <div class="form-group">
            <div class="col-sm-10"><input id="has_evaluation_factor" type="checkbox" ng-click="clearRelated($event, 'evaluation_factor_importance')" [(ngModel)]="nooData.has_evaluation_factor" ng-false="undefined" ng-true="'ESCO technical competencies and experience, management approach, and past performance are of equal importance.'"/>
              ESCO technical competencies and experience, management approach, and past performance are of equal importance.
            </div>
          </div>
          <div class="form-group">
            <label for="evaluation_factor_importance" class="col-sm-2 control-label">Write in relative importance of evaluation factors</label>
  
            <div class="col-sm-10">
        <textarea rows="4" class="form-control" ng-init="isDisabled = true" ng-disabled="nooData.has_evaluation_factor" id="evaluation_factor_importance" name="evaluation_factor_importance"
        [(ngModel)]="nooData.evaluation_factor_importance"></textarea>
            </div>
          </div>
  
        </div>
      </div>
  
  </div>
  

<!--Modal Text-->

<jw-modal  id="evaluationFactors" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="closeMOdal('evaluationFactors')"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
          <h4 class="modal-title" id="idSignificantFactors">Significant Evaluation Factors</h4>
        </div>
        <div class="modal-body">
          <p><strong>Significant Evaluation Factors (in NOO)</strong><br><br>
            Federal ESPC regulations and the DOE ESPC IDIQ require procuring agencies to state in their NOOs.
          </p>
          <p>The significant evaluation factors disclosed in the NOO must be used by the procuring agency to evaluate ESCOs’ responses.</p>
          <p><span style="text-decoration: underline">Evaluation criteria</span> are not included in the NOO, but appear in the NOO-Response-Evaluation forms.  Evaluation criteria more specifically reflect the agency’s project priorities, and are the particular measures of ESCO qualifications on which the agency bases its evaluations and ratings of the ESCOs. The agency’s selection of ESCO must be supported by its evaluation of ESCO’s strengths, weaknesses, and significant weaknesses or deficiencies for each criterion.</p>
          <p>Input will generate an <em>NOO-response-evaluation form</em> –  a Word document that can be edited by the agency. Also, several evaluation criteria based on the best-practice NOO are entered by default in the NOO-Response-Evaluation form.</p>
          <p><span style="text-decoration: underline">Relative importance of evaluation factors</span>.  Agencies are required to state the relative importance of the evaluation factors at the end of Section 6. The default for the NOO template is:    ESCO technical competencies and experience, general/management approach, and past performance are of equal significance. Agencies may specify other rankings, e.g., the first is more significant and all others are equal, etc.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="closeMOdal('evaluationFactors')">Close</button>
        </div>
      </div>
    </div>
  </jw-modal>
  
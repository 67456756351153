
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { ModalModule } from './_modal';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { ChecklistModule } from 'angular-checklist';


import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { EscoSelectionComponent } from './esco-selection/esco-selection.component';
import { SubmittalOfExpressionsOfInterestComponent } from './submittal-of-expressions-of-interest/submittal-of-expressions-of-interest.component';
import { NoticeAndInvitationComponent } from './notice-and-invitation/notice-and-invitation.component';
import { ObjectivesComponent } from './objectives/objectives.component';
import { FacilityOverviewComponent } from './facility-overview/facility-overview.component';
import { EvaluationCriteriaComponent } from './evaluation-criteria/evaluation-criteria.component';
import { RequiredSubmittalsComponent } from './required-submittals/required-submittals.component';
import { EvaluationFactorsComponent } from './evaluation-factors/evaluation-factors.component';
import { TrueFalseValueDirective } from './true-false-value.directive';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    EscoSelectionComponent,
    NoticeAndInvitationComponent,
    SubmittalOfExpressionsOfInterestComponent,
    ObjectivesComponent,
    FacilityOverviewComponent,
    EvaluationCriteriaComponent,
    RequiredSubmittalsComponent,
    EvaluationFactorsComponent,
    TrueFalseValueDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ModalModule,
    NgxWebstorageModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ChecklistModule
   
  ],
  
 
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

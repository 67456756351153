export const nooDataDefault = {
    issue_date: '',
    agency_name: '',
    agency_name_location: '',
    agency_name_site: '',
    mission_statement: '',
    facility_name: '',
    facility_building_description: '',
    noteworthy_site_conditions: '',
    total_sq_ft: '',
    page_number_max: '5 pages',
    font_size_min: '12 pt',
    e_1: '',
    e_2: 'KWh',
    e_3: '',
    e_4: 'KWh',
    e_5: '',
    ed_1: '',
    ed_2: 'KW',
    ed_3: '',
    ed_4: 'KW',
    ed_5: '',
    ng_1: '',
    ng_2: 'MMBtu',
    ng_3: '',
    ng_4: 'MMBtu',
    ng_5: '',
    pw_1: '',
    pw_2: 'Kgallons',
    pw_3: '',
    pw_4: 'Kgallons',
    pw_5: '',
    np_1: '',
    np_2: 'Kgallons',
    np_3: '',
    np_4: 'Kgallons',
    np_5: '',
    o: '',
    o_1: '',
    o_2: '',
    o_3: '',
    o_4: '',
    o_5: '',
    o2: '',
    o2_1: '',
    o2_2: '',
    o2_3: '',
    o2_4: '',
    o2_5: '',
    tc_1: '',
    tc_3: '',
    tc_5: '',
    facilityTypes: [{
      label: ''
    }],
    siteConditions: [{
      label: ''
    }],
    chpEvaluation: [{
      label: ''
    }],
    energyEcms : [{
     label: ''
    }],
    waterEcms: [{
      label: ''
      }],
    eeds: [{
      label: ''}],
    eevs: [{
      label: ''}],
    idiqsExplores: [{
      label:''}],
    eeurs: [{
      label: ''}],
    eets:[{
      label: ''}],
    evaluationWater:[{
      label: ''}],
      evaluationChp:[{
      label: ''}],

    contracting_officer: '',
    agency_ombudsman: '',
    co_email_address: '',
    contact_info: '',
    agency_ombudsman_contact_info: '',
    facility_address: '',
    default_statement_of_objectives: '',
    multiple_to_text: '',
    small_business_setaside: '',
    small_business_setaside_text: '',
    boilerplateStatementOfObjectives: '',
    //useBoilerplateStatementOfObjectives: true,
    statementOfObjectives: '',
    generalObjectives: [],
    other_goal_textarea: '',
    has_comprehensive_savings_deep_energy: '',
    has_comprehensive_savings_deep_water: '',
    has_specific_level: '',
    use_has_specific_level: '',
    has_decrease_intensity: '',
    has_decrease_water_consumption: '',
    has_decrease_maintenance_costs: '',
    has_reduce_utility_costs: '',
    has_reduce_ozone: '',
    has_reduce_greenhouse: '',
    has_reduce_fossil: '',
    has_gas_reduction: '',
    has_emissions_solutions: '',
    has_emissions_evaluation: '',
    has_ECMs_evaluation: '',
    has_broad_study_tech: '',
    has_broad_study: '',
    has_island_mode: '',
    has_black_start: '',
    has_op_main: '',
    has_redundant_backup: '',
    has_scalable_systems: '',
    has_duel_fuel: '',
    has_consider_esa: '',
    has_consider_esa_ecm: '',
    has_penalty: '',
    use_has_outage_solutions: '',
    use_has_availability_level: '',
    has_outage_solutions: '',
    has_availability_level: '',
    other_chp_installations_objective_textarea: '',
    other_specific_chp_objective_textarea: '',
    other_reduction_goal_textarea: '',
    has_thoroughly_explore: '',
    has_include_diversity: '',
    has_setaside: '',
    specificGoals: [],
    has_develop_renewable: '',
    has_utilize_renewable: '',
    has_fossil_fuel: '',
    has_both_traditional_and_renewable: '',
    has_power_purchasing_agreement: '',
    has_rebate_incentive: '',
    has_energy_star: '',
    has_emcs_metering: '',
    other_specific_ecms_textarea: '',
    has_evalCriteriaCHP_heat_power: '',
    has_chp_plant_installation: '',
    has_year_round_steam: '',
    has_utilize_waste_heat: '',
    has_operate_thru_grid_outage: '',
    has_utilize_bios: '',
    has_reduce_use_boilers: '',
    has_reduce_demand_utility: '',
    has_reduce_greenhouse_emissions: '',
    has_lower_cost: '',
    other_specific_chp_textarea: '',
    other_water_goal_textarea: '',
    has_major_water: '',
    has_water_balance: '',
    has_water_source: '',
    has_water_use: '',
    has_non_potable_water_use: '',
    other_water_objective_textarea: '',
    single_esco_text: '',
    multiple_projects_text: '',
    other_submittals: '',
    other_evaluation_factors: '',
    evaluation_factor_importance: 'ESCO technical competencies and experience, management approach, and past performance are of equal importance.',
    ef: 'ESCO technical competencies and experience, management approach, and past performance are of equal importance.',
    has_evaluation_factor: '',
    csCoName: '',
    csCoTitle: '',
    csCoEmail: '',
    responseDate: '',
    responseTime: '',
    questionDate: '',
    csCoNameAlt: '',
    csCoEmailAlt: '',
    signatureName: '',
    furtherTitle: '',
    has_multiple_tos: '',
   

    use_hase_Objectives_ESA: '',
    Objectives_ESA_textarea:'',
    has_Objectives_ESA: '',

    use_has_system_size:'',
    system_size_value:'',
    has_system_size: '',

    use_has_system_type: '',
    system_type_value:'',
    has_system_type: '',
    
    use_has_area_available: '',
    area_available_value:'',
    has_area_available: '',

    has_storage_systems: '',
    has_study_based_commissioning: '',
    has_monitoring_based_commissioning: '',
    has_data_center_improvements: '',
    has_50001_ready_program: '',
    has_smart_laboratories_program: '',
};
  

export const idiqEcms = [
    {value: 0, label: 'Boiler Plant Improvements', code: 'TC.1'},
    {value: 1, label: 'Chiller Plant Improvements', code: 'TC.2'},
    {value: 2, label: 'Building Automation Systems (BAS)/Energy Management Control Systems (EMCS)', code: 'TC.3'},
    {value: 3, label: 'Heating, Ventilating, and Air Conditioning (HVAC, not including boilers, chillers, and Building Automation System/Energy Monitoring/Management Control System (EMCS))', code: 'TC.4'},
    {value: 4, label: 'Lighting Improvements', code: 'TC.5'},
    {value: 5, label: 'Building Envelope Modifications ', code: 'TC.6'},
    {value: 6, label: 'Chilled Water, Hot Water, and Steam Distribution Systems', code: 'TC.7'},
    {value: 7, label: 'Electric Motors and Drives', code: 'TC.8'},
    {value: 8, label: 'Refrigeration', code: 'TC.9'},
    {value: 9, label: 'Distributed Generation', code: 'TC.10'},
    {value: 10, label: 'Renewable Energy Systems', code: 'TC.11'},
    {value: 11, label: 'Energy/Utility Distribution Systems', code: 'TC.12'},
    {value: 12, label: 'Water and Sewer Conservation Systems', code: 'TC.13'},
    {value: 13, label: 'Electrical Peak Shaving/Load Shifting', code: 'TC.14'},
    {value: 14, label: 'Energy Cost Reduction Through Rate Adjustments', code: 'TC.15'},
    {value: 15, label: 'Energy Related Process Improvements', code: 'TC.16'},
    {value: 16, label: 'Commissioning', code: 'TC.17'},
    {value: 17, label: 'Advanced Metering Systems', code: 'TC.18'},
    {value: 18, label: 'Appliance/Plug-load reductions', code: 'TC.19'}
  ];


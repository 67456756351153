export const esros = [
    {value: 0, label: 'Microgrid'},
    {value: 1, label: 'Battery Storage'},
    {value: 2, label: 'Thermal Storage'},
    {value: 3, label: 'Combined Heat and Power (CHP)'},
    {value: 4, label: 'Solar PV'},
    {value: 5, label: 'Wind'},
    {value: 6, label: 'Biomass'},
    {value: 7, label: 'Fuel Cells'},
    {value: 8, label: 'Fossil Fuel Generator'},
    {value: 9, label: 'Other'}
  ];

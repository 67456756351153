<div class="jumbotron">
  <div class="container">
    <h2>7 Instructions for ESCO Submittal of Expressions of Interest</h2>

    <p><strong>Section 7</strong> contains the final wrap-up for completion of the template creation.</p>
  </div>
</div>
<div class="container form-horizontal">
  <div class="row">
    <div class="col-md-6">

      <div class="panel panel-default">
        <div class="panel-heading">
          <h3 class="panel-title">Fill out the form below to populate the default text for section 7 at left.</h3>
        </div>
        <div class="panel-body">
          
          <div class="form-group">
            <label for="csCoName" class="col-sm-4 control-label">CS/CO Name:</label>

            <div class="col-sm-8">
              <input class="form-control" type="text" id="csCoName" name="csCoName" [(ngModel)]="nooData.csCoName"  />
            </div>
          </div>
          <div class="form-group">
            <label for="csCoTitle" class="col-sm-4 control-label">CS/CO Title:</label>

            <div class="col-sm-8">
              <input class="form-control" type="text" id="csCoTitle" name="csCoTitle" [(ngModel)]="nooData.csCoTitle" />
            </div>
          </div>
          <div class="form-group">
            <label for="csCoEMail" class="col-sm-4 control-label">CS/CO Email:</label>

            <div class="col-sm-8">
              <input class="form-control" type="text" id="csCoEmail" name="csCoEmail" [(ngModel)]="nooData.csCoEmail" />
            </div>
          </div>
          <div class="form-group">
            <label for="responseDate" class="col-sm-4 control-label">NOO Response Date:</label>

            <div class="col-sm-8">
              <input class="form-control" type="text" id="responseDate" [(ngModel)]="nooData.responseDate"
                     name="responseDate" />
            </div>
          </div>
          <div class="form-group">
            <label for="responseTime" class="col-sm-4 control-label">NOO Response Time:</label>

            <div class="col-sm-8">
              <input class="form-control" type="text" id="responseTime" [(ngModel)]="nooData.responseTime"
                     name="responseTime" />
            </div>
          </div>
          <div class="form-group">
            <label for="questionDate" class="col-sm-4 control-label">Questions Due Date:</label>

            <div class="col-sm-8">
              <input class="form-control" type="text" id="questionDate" [(ngModel)]="nooData.questionDate"
                     name="questionDate" />
            </div>
          </div>
         
          <div class="form-group">
            <label for="signatureName" class="col-sm-4 control-label">Signature Name:</label>

            <div class="col-sm-8">
              <input class="form-control" type="text" id="signatureName" name="signatureName"
              [(ngModel)]="nooData.signatureName" />
            </div>
          </div>
         
        </div>
      </div>

    </div>

    <div class="col-md-6">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h3 class="panel-title">Default text</h3>
        </div>
        <div class="panel-body">
          ESCOs are required to submit their expressions of interest via e-mail to <mark> {{nooData.csCoName|| "CO/CS Name"}}</mark>
          <mark>{{nooData.csCoTitle|| "CS/CO title"}}</mark>,<mark>{{nooData.csCoEmail|| "CS/CO email address" }}</mark> prior to <mark>{{nooData.responseDate|| "NOO Response Date" }}, {{nooData.responseTime|| "NOO Response Time"}}</mark>.
          If you are not interested in participating, please provide a negative response. <br/><br/>
          No data or literature of any kind, other than that specified in this notice, will be accepted, nor will discussions take place at this time. <br/><br/>
          ESCOs may submit general questions or questions about the selection process in writing prior to <mark>{{nooData.questionDate|| " Questions Due Date"}}</mark>. All questions posed by
          ESCOs along with Agency responses to those questions will be distributed to all contractors. <br/><br/>
          Submit questions via email to <mark> {{nooData.csCoName|| "CO/CS Name"}}</mark>,
          <mark>{{nooData.csCoEmail|| "CS/CO email address" }}</mark>.<br/><br/>
          Each ESCO must designate one point of contact for all communications between the ESCO and <mark>{{nooData.agency_name|| "Agency"}}</mark> . Please provide name, title, phone
          number, and email address for this individual. No other communications regarding this notice or requirement
          are allowed. <br/><br>
          Sincerely,<br/><br/>

          <mark>{{nooData.signatureName|| "Signature Name" }}</mark><br>
          Contracting Officer<br>
          <br>
          cc:  	 FEMP Federal Project Executive<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FEMP Project Facilitator<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DOE Golden Office<br>
          <br><br>
          [See Complete NOO Template for optional Attachment A:  Past Performance Questionnaire]
        </div>
      </div>
    </div>
  </div>
</div>


